import { Link } from '@scout24ch/fs24-design-system';
import * as React from 'react';
import { useTranslations } from '../../../hooks';

export const DataProtectionQuestion: React.FC = () => {
  const t = useTranslations();

  return (
    <p>
      {t(`acceptPrivacyPolicy-start`)}{' '}
      <Link href={t(`acceptPrivacyPolicy-informationUrl`)} target="_blank" rel="noopener noreferrer">
        {t(`acceptPrivacyPolicy-informationText`)}
      </Link>{' '}
      {t(`acceptPrivacyPolicy-mid`)}{' '}
      <Link href={t(`acceptPrivacyPolicy-dataProtectionUrl`)} target="_blank" rel="noopener noreferrer">
        {t(`acceptPrivacyPolicy-dataProtectionText`)}
      </Link>{' '}
      {t(`acceptPrivacyPolicy-end`)}{' '}
      <Link href={t(`acceptPrivacyPolicy-termsUrl`)} target="_blank" rel="noopener noreferrer">
        {t(`acceptPrivacyPolicy-termsLinkText`)}
      </Link>
      .
    </p>
  );
};
