import * as React from 'react';
import { fs24, Heading, Spinner } from '@scout24ch/fs24-design-system';

type PlaceholderPageProps = {
  children?: React.ReactNode;
  showSpinner?: boolean;
};

export function PlaceholderPage(props: PlaceholderPageProps): React.ReactElement {
  const { children, showSpinner = true } = props;
  const containerStyles = {
    minHeight: '80vh',
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    justifyContent: !!children ? 'flexStart' : 'center',
    backgroundColor: 'white',
  };
  return (
    <fs24.div {...containerStyles}>
      {children && (
        <Heading as="h2" mt={[24, 40, 56]} textAlign="center" px={4}>
          {children}
        </Heading>
      )}
      {showSpinner && <Spinner role="status" color="aqua.500" />}
    </fs24.div>
  );
}
