import * as React from 'react';
import { Field } from '../../../enums';
import { useGetInquiryErrors, useGetInquiryFields, useHandleFieldChange } from '../../../hooks';
import { fetchInsuranceSum } from '../../../state/inquiry/inquiry.actionCreators';
import { InternalInquiryState } from '../../../state/inquiry/inquiry.reducer';
import { InternalInquiryErrorState } from '../../../state/inquiryError/inquiryError.reducer';
import { useDispatch } from '../../../state/rootReducer';
import { IsDogOwnerQuestion, NumberOfDogsQuestion } from '../../Questions';
import { RenderIf } from '../../RenderIf';

export const DogQuestions: React.FC = () => {
  const fields = useGetInquiryFields() as InternalInquiryState;
  const errors = useGetInquiryErrors() as InternalInquiryErrorState;
  const dispatch = useDispatch();
  const handleChange = useHandleFieldChange();

  const handleIsDogOwnerChange = (newValue: boolean) => {
    dispatch(fetchInsuranceSum());
    if (newValue === false) {
      handleChange(Field.IsDogOwner, newValue, Field.PreviousInsurer);
      handleChange(Field.NumberOfDogs, null);
    } else {
      handleChange(Field.IsDogOwner, newValue, Field.NumberOfDogs);
    }
  };

  return (
    <>
      <IsDogOwnerQuestion
        error={errors.isDogOwner}
        onChange={handleIsDogOwnerChange}
        currentValue={fields.isDogOwner}
      />
      <RenderIf condition={!!fields.isDogOwner}>
        <NumberOfDogsQuestion
          error={errors.numberOfDogs}
          currentValue={fields.numberOfDogs}
          onChange={(newValue) => handleChange(Field.NumberOfDogs, newValue, Field.PreviousInsurer)}
        />
      </RenderIf>
    </>
  );
};
