import * as actions from './selectedPremiums.actionTypes';

export const setSelectedPremiums = (premiums) => {
  return { type: actions.SET_SELECTED_PREMIUMS, premiums } as const;
};

export const addSelectedPremium = (premium) => {
  return { type: actions.ADD_SELECTED_PREMIUM, premium } as const;
};

export const removeSelectedPremium = (premiumId: number) => {
  return { type: actions.REMOVE_SELECTED_PREMIUM, premiumId } as const;
};

export const clearSelectedPremiums = () => {
  return { type: actions.CLEAR_SELECTED_PREMIUMS } as const;
};

export type ActionType = ReturnType<
  typeof addSelectedPremium | typeof removeSelectedPremium | typeof clearSelectedPremiums | typeof setSelectedPremiums
>;
