import { useOnMount } from '@scout24ch/fs24-hooks';
import { Button, FormContainer, QuestionStack } from '@scout24ch/fs24-design-system';
import { cookieStorage } from '@scout24ch/fs24-utils';
import { useRouter } from 'next/router';
import * as React from 'react';
import { useGoogleTagManager } from '@scout24ch/fs24-gtm-react';
import Head from 'next/head';
import { requestInsuranceQuote } from '../../api/insuranceQuote';
import { COOKIE_LAST_INQUIRY_ID } from '../../common/constants';
import { SUBMIT_BUTTON } from '../../constants';
import { Field, ProductType, Page, Step } from '../../enums';
import { useHandleFieldChange, useLocale, useTranslations, useUser } from '../../hooks';
import { mapHouseholdInquiryToRequest } from '../../mappers/inquiryMappers';
import { getDamagesSectionIsDone, getHouseholdInquiryFields } from '../../state/inquiry/inquiry.selectors';
import { setHouseholdInquiryError } from '../../state/inquiryError/inquiryError.actionCreators';
import { getHouseholdErrors } from '../../state/inquiryError/inquiryError.selectors';
import { getCurrentLanguageCode } from '../../state/language/language.selectors';
import { doSetLoading } from '../../state/loading/loading.actionCreators';
import { getIsLoading } from '../../state/loading/loading.selectors';
import { addPremiums } from '../../state/premiums/premiums.actionCreators';
import { useDispatch, useSelector } from '../../state/rootReducer';
import { clearSelectedPremiums } from '../../state/selectedPremiums/selectedPremiums.actionCreators';
import { disableScrolling, saveLastInquiryKey, scrollToFirstErrorField } from '../../utils';
import { goToErrorRoute, goToHouseholdResultRoute } from '../../utils/router/clientNavigationUtils';
import { captureException } from '../../utils/sentry';
import { hasValue } from '../../components/householdInsuranceValidators';
import {
  DamagesSection,
  HouseholdContentSection,
  HouseholdPropertySection,
  PersonalSection,
} from '../../components/InquirySections';
import { CoveragesSection } from '../../components/InquirySections/CoveragesSection/CoveragesSection';
import { LoadingIndicator } from '../../components/LoadingIndicator/LoadingIndicator';
import { DataProtectionQuestion } from '../../components/Questions/DataProtectionQuestion';
import { RenderIf } from '../../components/RenderIf';
import { StepNavigation } from '../../components/StepNavigation';
import { householdInquiryHasErrors, householdInquiryValidator } from './householdInquiryValidator';
import { TranslationKey } from '../../types';

export const HouseholdInquiryPage: React.FC = () => {
  const currentLanguageCode = useSelector(getCurrentLanguageCode);
  const [hasRequestId, setHasRequestId] = React.useState<boolean>(false);
  const t = useTranslations();
  const inquiryErrors = useSelector(getHouseholdErrors);
  const isLoading = useSelector(getIsLoading);
  const damagesSectionDone = useSelector(getDamagesSectionIsDone);
  const fields = useSelector(getHouseholdInquiryFields);
  const { user } = useUser();
  const profileEmail = user?.email;
  const dispatch = useDispatch();
  const { posessionType } = useRouter().query;
  const handleChange = useHandleFieldChange();
  const { setEmailHashCookies } = useGoogleTagManager();
  const locale = useLocale();

  React.useEffect(() => {
    if (profileEmail) {
      handleChange(Field.EmailAddress, profileEmail);
    }
    // should only happen on mount
    //eslint-disable-next-line
  }, [profileEmail]);

  useOnMount(() => {
    const requestId = cookieStorage.getItem(COOKIE_LAST_INQUIRY_ID);

    if (requestId) {
      setHasRequestId(true);
    }

    if (posessionType) {
      handleChange(Field.PossessionType, posessionType);
    }

    dispatch(addPremiums([]));
    dispatch(clearSelectedPremiums());

    if (damagesSectionDone) {
      disableScrolling();
    }
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!damagesSectionDone) {
      return;
    }

    const errors = householdInquiryValidator(fields, inquiryErrors);
    dispatch(setHouseholdInquiryError(errors));
    if (householdInquiryHasErrors(errors)) {
      scrollToFirstErrorField();
    } else {
      try {
        dispatch(doSetLoading(true));
        window.scrollTo(0, 0);
        const requestId = await requestInsuranceQuote(
          mapHouseholdInquiryToRequest(fields, currentLanguageCode, false, false, user?.email ?? undefined),
        );
        cookieStorage.setItem(COOKIE_LAST_INQUIRY_ID, requestId);
        const householdLabel = t(fields.householdType! as TranslationKey);
        const text = `${householdLabel} ${fields.city.postalCode} ${fields.city.name}`;
        saveLastInquiryKey(requestId, text, currentLanguageCode);
        setEmailHashCookies(fields.emailAddress!);
        goToHouseholdResultRoute(String(requestId));
      } catch (err) {
        captureException(err);
        goToErrorRoute(ProductType.Household, '', Page.Inquiry);
      }
    }
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Head>
        <link rel="canonical" href={`https://haushaltsversicherung.financescout24.ch/${locale}/inquiry`} />
      </Head>
      <StepNavigation currentStep={Step.General} />
      <FormContainer p={[2, null]} mb={20}>
        <h2 className="page-title">{t('inquiryPageTitle')}</h2>
        <QuestionStack as="form" onSubmit={handleSubmit}>
          <HouseholdPropertySection />
          <RenderIf condition={hasValue(fields.numberOfRooms)}>
            <HouseholdContentSection />
          </RenderIf>
          <RenderIf condition={hasValue(fields.electricalAppliances)}>
            <CoveragesSection />
            <PersonalSection />
          </RenderIf>
          <DamagesSection />
          <RenderIf condition={damagesSectionDone}>
            <DataProtectionQuestion />
            <Button
              width="full"
              variant="primary"
              className={hasRequestId ? 'fixed-submit-button' : undefined}
              onClick={handleSubmit}
            >
              {t('submitButtonText')}
            </Button>
            <div className="scroll-checkpoint" data-scroll={SUBMIT_BUTTON} />
          </RenderIf>
        </QuestionStack>
      </FormContainer>
    </>
  );
};
