import { useGoogleTagManager } from '@scout24ch/fs24-gtm-react';
import { useOnMount } from '@scout24ch/fs24-hooks';
import * as React from 'react';
import { gtmEvents } from '../../../config/gtm/events/household';
import { Field, InquirySection } from '../../../enums';
import { useGetInquiryErrors, useGetInquiryFields, useHandleFieldChange } from '../../../hooks';
import { InternalInquiryState } from '../../../state/inquiry/inquiry.reducer';
import { InternalInquiryErrorState } from '../../../state/inquiryError/inquiryError.reducer';
import { hasValue } from '../../householdInsuranceValidators';
import { ClothingPriceQuestion, ElectricAppliancesPriceQuestion, FurniturePriceQuestion } from '../../Questions';
import { RenderIf } from '../../RenderIf';

export const HouseholdContentSection: React.FC = () => {
  const fields = useGetInquiryFields() as InternalInquiryState;
  const errors = useGetInquiryErrors() as InternalInquiryErrorState;
  const handleChange = useHandleFieldChange();
  const gtm = useGoogleTagManager();

  useOnMount(() => {
    if (!fields || !fields.furnishing) {
      gtm.push(gtmEvents.householdInsurance.inquiryPage.reached.furnishingAndProperty());
    }
  });

  return (
    <>
      <FurniturePriceQuestion
        error={errors.furnishing}
        onChange={(newValue) => handleChange(Field.Furnishing, newValue, Field.Clothing)}
        currentValue={fields.furnishing}
      />
      <RenderIf condition={hasValue(fields.furnishing)}>
        <ClothingPriceQuestion
          error={errors.clothing}
          currentValue={fields.clothing}
          onChange={(newValue) => handleChange(Field.Clothing, newValue, Field.ElectricalAppliances)}
        />
      </RenderIf>
      <RenderIf condition={hasValue(fields.clothing)}>
        <ElectricAppliancesPriceQuestion
          error={errors.electricalAppliances}
          currentValue={fields.electricalAppliances}
          onChange={(newValue) => handleChange(Field.ElectricalAppliances, newValue, InquirySection.Coverages)}
        />
      </RenderIf>
    </>
  );
};
