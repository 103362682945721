import { Field, HouseholdType } from '../../enums';
import { InquiryState } from '../../state/inquiry/inquiry.reducer';
import { InquiryErrorState, InternalInquiryErrorState } from '../../state/inquiryError/inquiryError.reducer';
import { hasCityError, hasDamagesErrors } from '../../state/privateLiabilityInquiry/privateLiability.validator';
import {
  conditionalFieldHasError,
  getChildrenBirthDatesErrors,
  getCityErrors,
  getDamageError,
  getDateOfBirthError,
  getEmailError,
  hasError,
  numberOfChildrenIsValid,
} from '../../components/householdInsuranceValidators';
import { insuranceStartDateValidator } from '../../components/InquirySections/InsuranceStartDate/insuranceStartDate.validator';
import { insuranceSumvalidator } from '../../components/InquirySections/InsuranceSum/insuranceSum.validator';

export const householdInquiryValidator = (
  fields: InquiryState,
  currentErrors: InquiryErrorState,
  shouldExcludeEmailValidation = false,
) => {
  const errors: InternalInquiryErrorState = { ...currentErrors };

  errors.possessionType = hasError(fields.possessionType);
  errors.householdType = hasError(fields.householdType);
  errors.householdOccupancyType = hasError(fields.householdOccupancyType);
  errors.isGroundFloorApartment =
    fields.householdType === HouseholdType.Apartment && hasError(fields.isGroundFloorApartment);
  errors.numberOfRooms = hasError(fields.numberOfRooms);
  errors.isMassive = hasError(fields.isMassive);
  errors.city = getCityErrors(fields.city, currentErrors.city);
  errors.furnishing = hasError(fields.furnishing);
  errors.clothing = hasError(fields.clothing);
  errors.electricalAppliances = hasError(fields.electricalAppliances);
  errors.insuranceStartDate = insuranceStartDateValidator(fields.insuranceStartDate!, currentErrors.insuranceStartDate);
  errors.insuranceSum = insuranceSumvalidator(fields.insuranceSum);
  errors.selfInflictedDamagesAmount = conditionalFieldHasError(
    fields.selfInflictedDamages,
    fields.selfInflictedDamagesAmount,
  );
  errors.theftNotAtHomeAmount = conditionalFieldHasError(fields.theftNotAtHome, fields.theftNotAtHomeAmount);
  errors.dateOfBirth = getDateOfBirthError(fields.dateOfBirth, errors.dateOfBirth);
  errors.childrenDatesOfBirth = getChildrenBirthDatesErrors(fields.childrenDatesOfBirth, errors.childrenDatesOfBirth);
  errors.numberOfInsurees = hasError(fields.numberOfInsurees);

  if (!shouldExcludeEmailValidation) {
    errors.emailAddress = getEmailError(fields.emailAddress);
  }

  errors.numberOfResidentsAgedUnder16 = !numberOfChildrenIsValid(
    fields.numberOfInsurees,
    fields.numberOfResidentsAgedUnder16,
  );
  errors.isDogOwner = fields.privateLiability ? hasError(fields.isDogOwner) : false;
  errors.numberOfDogs = fields.privateLiability
    ? conditionalFieldHasError(fields.isDogOwner, fields.numberOfDogs)
    : false;

  errors.hasReceivedCompensationForHousehold = hasError(fields.hasReceivedCompensationForHousehold);
  errors.hasReceivedCompensationForPrivateLiability =
    fields.privateLiability && hasError(fields.hasReceivedCompensationForPrivateLiability);

  errors.numberOfHouseholdDamageCompensations = conditionalFieldHasError(
    fields.hasReceivedCompensationForHousehold,
    fields.numberOfHouseholdDamageCompensations,
  );

  errors.numberOfPrivateLiabilityDamageCompensations =
    fields.privateLiability &&
    conditionalFieldHasError(
      fields.hasReceivedCompensationForPrivateLiability,
      fields.numberOfPrivateLiabilityDamageCompensations,
    );
  errors.contractTerminatedByPreviousInsurer = hasError(fields.contractTerminatedByPreviousInsurer);
  errors.householdDamages = fields.householdDamages.map(getDamageError);
  errors.privateLiabilityDamages = fields.privateLiabilityDamages.map(getDamageError);
  errors.grossNegligence = fields.privateLiability && hasError(fields.grossNegligence);
  errors.nonOwnerDriverInsurance = fields.privateLiability && hasError(fields.nonOwnerDriverInsurance);
  errors.previousInsurer = hasError(fields.previousInsurer);
  errors.reasonForCancellation = conditionalFieldHasError(
    fields.contractTerminatedByPreviousInsurer,
    fields.reasonForCancellation,
  );
  errors.dataProtectionAccepted = fields.dataProtectionAccepted === false;

  return errors as InquiryErrorState;
};

export const householdInquiryHasErrors = (errors: InquiryErrorState) => {
  const fields = {
    [Field.City]: hasCityError(errors.city),
    [Field.DateOfBirth]: errors.dateOfBirth !== null,
    [Field.ChildrenDatesOfBirth]: errors.childrenDatesOfBirth.some((date) => date !== null),
    [Field.EmailAddress]: errors.emailAddress !== null,
    [Field.InsuranceStartDate]: errors.insuranceStartDate !== null,
    [Field.PrivateLiabilityDamages]: hasDamagesErrors(errors.privateLiabilityDamages),
    [Field.HouseholdDamages]: hasDamagesErrors(errors.householdDamages),
    [Field.InsuranceSum]: errors.insuranceSum !== null,
  };

  const updatedErrors = { ...errors, ...fields };

  return Object.values(updatedErrors).some((error) => error === true);
};
