import React from 'react';
import { DeviceType, getCurrentDeviceType } from '@scout24ch/fs24-utils';
import { useTranslations } from '../../../hooks';
import { Modal, ModalProps } from '../Modal';

export const InsuranceSumWarningModal = (props: ModalProps) => {
  const t = useTranslations();
  return (
    <Modal
      isOpen={props.isOpen}
      closeLabel={props.closeLabel}
      onClose={props.onClose}
      bannerImage={
        typeof window !== 'undefined' && getCurrentDeviceType() === DeviceType.DESKTOP
          ? '/static/assets/img/insurane-sum-warning-modal-desktop.jpg'
          : '/static/assets/img/insurane-sum-warning-modal.jpg'
      }
    >
      <div className="insurance-sum-modal">
        <h3 className="main-text-title">{t('warningModalTitle')}</h3>
        <div className="main-text">
          <p>{t('warningModalTitleMainOne')}</p>
          <p>{t('warningModalTitleMainTwo')}</p>
        </div>
        <h4>{t('warningModalSecondaryTitle')}</h4>
        <p dangerouslySetInnerHTML={{ __html: t('warningModalSecondaryTextFirst') }} />
        <p>
          <strong>{t('warningSumModalSecondaryTextFirstPoint')}</strong>
        </p>
        <p dangerouslySetInnerHTML={{ __html: t('warningSumModalSecondaryTextFirstPointText') }} />
        <p>
          <strong>{t('warningSumModalSecondaryTextSecondPoint')}</strong>
        </p>
        <p dangerouslySetInnerHTML={{ __html: t('warningSumModalSecondaryTextSecondPointTextFirst') }} />
        <div className="main-text">
          <p className="main-text">{t('warningSumModalSecondaryTextSecondPointTextSecond')}</p>
        </div>
        <p className="main-text">{t('warningSumModalSecondaryTextSecondPointTextThird')}</p>
      </div>
    </Modal>
  );
};
