import { ValidationCode, ValidationResult } from '@scout24ch/fs24-forms';
import { DateError } from '../../enums';
import {
  SET_HOUSEHOLD_INQUIRY_ERROR,
  SET_DAMAGE_AMOUNT_ERROR,
  UPDATE_DATE_ERROR_ARRAY,
  SET_DATE_ERROR,
  SET_HOUSEHOLD_FIELD_ERROR,
} from './inquiryError.actionTypes';
import { CityError, DamageError } from '../../types';
import { InquiryErrorState } from './inquiryError.reducer';

export const setHouseholdInquiryError = (errors: InquiryErrorState) => {
  return { type: SET_HOUSEHOLD_INQUIRY_ERROR, errors } as const;
};

export const setDamageAmountError = (error: boolean, index: number, name: string) => {
  return { type: SET_DAMAGE_AMOUNT_ERROR, error, index, name } as const;
};

export const updateDatesArray = (newSize: number) => {
  return { type: UPDATE_DATE_ERROR_ARRAY, newSize } as const;
};

export const setDateError = (error: DateError | null, index: number) => {
  return { type: SET_DATE_ERROR, error, index } as const;
};

export const setHouseholdFieldError = (
  field: string,
  error: boolean | null | null[] | CityError | DateError | ValidationCode | ValidationResult | DamageError[],
) => {
  return { type: SET_HOUSEHOLD_FIELD_ERROR, field, error } as const;
};

export type ActionType = ReturnType<
  | typeof setHouseholdInquiryError
  | typeof setDamageAmountError
  | typeof updateDatesArray
  | typeof setDateError
  | typeof setHouseholdFieldError
>;
