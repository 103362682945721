import * as React from 'react';
import { CurrencyQuestion, Link, theme, useMediaQuery } from '@scout24ch/fs24-design-system';
import { formatCurrencyToInteger } from '@scout24ch/fs24-utils';
import { useGoogleTagManager } from '@scout24ch/fs24-gtm-react';
import { gtmEvents } from '../../../config/gtm/events/household';
import { Field } from '../../../enums';
import {
  useGetInquiryErrors,
  useGetInquiryFields,
  useHandleFieldChange,
  useIsClient,
  useTranslations,
} from '../../../hooks';
import { setHouseholdFieldError } from '../../../state/inquiryError/inquiryError.actionCreators';
import { useDispatch } from '../../../state/rootReducer';
import { asInt } from '../../../utils';
import { InsuranceSumWarning } from '../../InsuranceSumWarning/InsuranceSumWarning';
import { InsuranceSumModal } from '../../modals/InsuranceSumModal/InsuranceSumModal';
import { InsuranceSumWarningModal } from '../../modals/InsuranceSumModal/InsuranceSumWarningModal';
import { RenderIf } from '../../RenderIf';
import { insuranceSumvalidator } from './insuranceSum.validator';
import { TranslationKey } from '../../../types';
import { InternalInquiryState } from '../../../state/inquiry/inquiry.reducer';
import { InternalInquiryErrorState } from '../../../state/inquiryError/inquiryError.reducer';

export const InsuranceSum: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
  const [warning, setWarning] = React.useState<boolean>(false);
  const t = useTranslations();
  const fields = useGetInquiryFields() as InternalInquiryState;
  const errors = useGetInquiryErrors() as InternalInquiryErrorState;
  const dispatch = useDispatch();
  const isClient = useIsClient();
  const handleChange = useHandleFieldChange();
  const [isMediumBreakpoint] = useMediaQuery(theme.breakpoints.md);
  const gtm = useGoogleTagManager();

  React.useEffect(() => {
    if (asInt(fields.insuranceSum) !== fields.recommendedInsuranceSum && fields.recommendedInsuranceSum > 0) {
      setWarning(true);
    }
    if (!fields || !fields.previousInsurer) {
      gtm.push(gtmEvents.householdInsurance.inquiryPage.reached.insuranceSumAndStart());
    }
    // should only happen on mount
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <h3 className="inquiry-section-title">
        {fields.recommendedInsuranceSum === 0
          ? t('insuranceSumNoCalculationSectionTitle')
          : t('insuranceSumSectionTitle')}
      </h3>
      <div className="insurance-sum">
        <CurrencyQuestion
          inputMode="numeric"
          name="insuranceSum"
          description={
            fields.recommendedInsuranceSum === 0
              ? undefined
              : t('insuranceSumIntro', {
                  amount: formatCurrencyToInteger(fields.recommendedInsuranceSum),
                })
          }
          label={t('insuranceSumQuestion')}
          errorMessage={errors.insuranceSum ? t(errors.insuranceSum.code as TranslationKey) : undefined}
          value={fields.insuranceSum}
          onValueChange={(value) => {
            const error = insuranceSumvalidator(asInt(value));
            dispatch(setHouseholdFieldError(Field.InsuranceSum, error));
            handleChange(Field.InsuranceSum, value);
            if (error === null && fields.recommendedInsuranceSum > 0) {
              if (asInt(String(value).replace("'", '')) !== asInt(fields.recommendedInsuranceSum)) {
                setWarning(true);
              }
            } else {
              setWarning(false);
            }
          }}
        />

        <RenderIf condition={warning && errors.insuranceSum === null}>
          <InsuranceSumWarning
            insuranceSum={asInt(fields.insuranceSum)}
            recommendedSum={fields.recommendedInsuranceSum}
          />
        </RenderIf>
        <RenderIf condition={fields.recommendedInsuranceSum > 0}>
          <Link
            variant="dark"
            mt={4}
            display="block"
            onClick={(e) => {
              e.preventDefault();
              if (isClient) {
                if (!isMediumBreakpoint) {
                  document.body.style.position = 'fixed';
                } else {
                  document.body.style.overflow = 'hidden';
                }
              }
              setModalIsOpen(true);
            }}
          >
            {warning && errors.insuranceSum === null ? t('insuranceSumWarningLink') : t('insuranceSumLink')}
          </Link>
        </RenderIf>
        <InsuranceSumModal isOpen={modalIsOpen && !warning} onClose={() => setModalIsOpen(false)} />
        <InsuranceSumWarningModal
          isOpen={modalIsOpen && warning}
          closeLabel={t('closeModalLabel')}
          onClose={() => setModalIsOpen(false)}
        />
      </div>
    </>
  );
};
