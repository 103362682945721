import {
  createGA4GTMECommerceEvent,
  ECommerceEventLabel,
  GtmActionField,
  GTMEcommerceEvent,
  GtmProduct,
  NOT_PROVIDED,
} from '@scout24ch/fs24-gtm-react';
import { PrivateLiabilityPremiumDto, QuoteDto } from '../../types/Responses';
import { sorted } from '../../utils';
import {
  gtmConsts,
  LIST_ID_HOUSEHOLD_INSURANCE,
  LIST_ID_PRIVATE_LIABILITY,
  LIST_NAME_HOUSEHOLD_INSURANCE,
  LIST_NAME_PRIVATE_LIABILITY,
  myGtmEvents,
} from './consts';

export interface StaticDataLayerGtmEvent {
  event: myGtmEvents.GTM_EVENT | myGtmEvents.GTM24_EVENT;
  eventCategory: string;
  eventAction: string;
  nonInteraction: true | undefined;
  [customEventKeys: string]: unknown;
}

export interface DataLayerGtmEvent extends StaticDataLayerGtmEvent {
  eventLabel: string;
  inquiry?: {
    [key: string]: any;
  };
  user?: {
    eh: string;
    emailHash: string;
  };
}

export const getProductId = (productId: string, productName: string, isPrivateLiability: boolean): string =>
  `${isPrivateLiability ? 'privateLiability' : 'household'}-${productId}-${productName}`;

export const staticGtmEventProps: {
  householdInsurance: StaticDataLayerGtmEvent;
  privateLiability: StaticDataLayerGtmEvent;
} = {
  householdInsurance: {
    event: myGtmEvents.GTM_EVENT,
    eventCategory: gtmConsts.eventCategory,
    eventAction: gtmConsts.eventActions.funnelReached,
    nonInteraction: undefined,
  },
  privateLiability: {
    event: myGtmEvents.GTM_EVENT,
    eventCategory: gtmConsts.privateLiabilityEventCategory,
    eventAction: gtmConsts.eventActions.funnelReached,
    nonInteraction: undefined,
  },
};

export const getImpressionslist = (
  premiums: readonly QuoteDto[] | readonly PrivateLiabilityPremiumDto[],
  sortOrder,
  hasNoPosition = false,
  isPrivateLiability: boolean,
) =>
  sorted<QuoteDto | PrivateLiabilityPremiumDto>(premiums, sortOrder).map((premium, i) => ({
    brand: premium.insuranceCompanyInfo.company,
    category: isPrivateLiability ? 'PrivateLiability_Insurance' : 'household_insurance',
    id: getProductId(String(premium.productId), premium.productName, isPrivateLiability),
    price: premium.premium,
    variant: 'Not_Provided',
    quantity: 1,
    position: hasNoPosition ? undefined : i + 1,
    name: premium.productName,
    list: isPrivateLiability ? 'PrivateLiability_Insurance_Resultlist' : 'Household_Insurance_Resultlist',
    dimension80: undefined,
  }));

export const prependZeros = (num: number | string, totalLength: number) => {
  return String(num).padStart(totalLength, '0');
};

export const createGTMECommerceEvent = (
  eventAction: string,
  eventLabel: ECommerceEventLabel,
  gtmProducts: GtmProduct[],
  actionField?: GtmActionField,
): GTMEcommerceEvent => {
  const list_id =
    eventLabel === ECommerceEventLabel.HOUSEHOLD_INSURANCE ? LIST_ID_HOUSEHOLD_INSURANCE : LIST_ID_PRIVATE_LIABILITY;
  const list_name =
    eventLabel === ECommerceEventLabel.HOUSEHOLD_INSURANCE
      ? LIST_NAME_HOUSEHOLD_INSURANCE
      : LIST_NAME_PRIVATE_LIABILITY;

  const products = gtmProducts.map((product) => ({
    id: `${eventLabel}-${prependZeros(product.id, 3)}`,
    name: `${product.brand} | ${product.name}`,
    currency: 'CHF',
    position: product.position ?? NOT_PROVIDED,
    brand: product.brand,
    category: eventLabel,
    list_id,
    list_name,
    transaction_type: product.transaction_type ?? NOT_PROVIDED,
    quantity: 1,
    price: product.price,
  }));

  return createGA4GTMECommerceEvent(eventAction, eventLabel, products, actionField);
};
