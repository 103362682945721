import * as React from 'react';
import { SingleChoiceSelection } from '..';

export interface BooleanSelectionProps {
  errorMessage?: string;
  title: string;
  intro?: string;
  name: string;
  trueLabel: string;
  falseLabel: string;
  trueIcon?: React.ReactNode;
  falseIcon?: React.ReactNode;
  currentValue: boolean | null;
  onChange(newValue: boolean): void;
}

export const BooleanSelection: React.FC<BooleanSelectionProps> = (props) => {
  const booleanOptions = [
    { value: true, label: props.trueLabel, icon: props.trueIcon },
    { value: false, label: props.falseLabel, icon: props.falseIcon },
  ];

  return (
    <SingleChoiceSelection
      errorMessage={props.errorMessage}
      name={props.name}
      options={booleanOptions}
      title={props.title}
      intro={props.intro}
      onChange={props.onChange}
      currentValue={props.currentValue}
    />
  );
};
