import * as React from 'react';
import { useTranslations } from '../../hooks';
import { Additional } from '../Icon';

interface InsuranceSumWarningProps {
  insuranceSum: number;
  recommendedSum: number;
}

export const InsuranceSumWarning = (props: InsuranceSumWarningProps) => {
  const t = useTranslations();
  const [warning, setWarning] = React.useState<string>('');

  React.useEffect(() => {
    if (props.insuranceSum < props.recommendedSum) {
      setWarning(t('insuranceSumTooLowWarning'));
    } else if (props.insuranceSum > props.recommendedSum) {
      setWarning(t('insuranceSumTooHighWarning'));
    } else {
      setWarning('');
    }
  }, [props.insuranceSum, props.recommendedSum, t]);

  return warning.length > 0 ? (
    <div className="warning">
      <Additional isWarning /> {warning}
    </div>
  ) : null;
};
