export const ACTION_VIEW_PRODUCT_LIST = 'view_product_list';
export const ACTION_VIEW_PRODUCT = 'view_product';
export const ACTION_SELECT_PRODUCT = 'select_product';
export const ACTION_PURCHASE_OFFER = 'purchase_offer';
export const ACTION_PURCHASE_DEAL = 'purchase_deal';

export const TRANSACTION_TYPE_DIRECT_DEAL = 'direct_deal';
export const TRANSACTION_TYPE_REQUEST_OFFER = 'request_offer';

export const LIST_ID_HOUSEHOLD_INSURANCE = 'household_insurance';
export const LIST_ID_PRIVATE_LIABILITY = 'private-liability';
export const LIST_NAME_HOUSEHOLD_INSURANCE = 'Household_Insurance_Resultlist';
export const LIST_NAME_PRIVATE_LIABILITY = 'Private_Liability_Resultlist';

export enum myGtmEvents {
  PAGE_VIEW = 'pageView',
  METADATA = 'metaData',
  GTM_EVENT = 'gtmEvent',
  GTM24_EVENT = 's24.gtmEvent',
}

export const gtmConsts = {
  eventCategory: 'Household_Insurance_Comparison',
  privateLiabilityEventCategory: 'PrivateLiability_Insurance_Comparision',
  privateLiabilityComparisonEventCategory: 'Private_Liability_Comparison',
  eventActions: {
    funnelReached: 'Funnel_Section_Reached',
  },
  defaultInitOptions: {
    primaryCategory: 'Household Insurance',
    secondaryCategory: 'Funnel',
    productCategory: 'Household Insurance',
  },
  privateLiabilityDefaultInitOptions: {
    primaryCategory: 'Private Liability',
    secondaryCategory: 'Funnel',
    productCategory: 'Private Liability',
  },
};
