import * as React from 'react';
import { Field } from '../../enums';
import { useGetInquiryErrors, useGetInquiryFields, useHandleFieldChange } from '../../hooks';
import { InternalInquiryState } from '../../state/inquiry/inquiry.reducer';
import { setHouseholdFieldError } from '../../state/inquiryError/inquiryError.actionCreators';
import { InternalInquiryErrorState } from '../../state/inquiryError/inquiryError.reducer';
import { useDispatch } from '../../state/rootReducer';
import { GrossNegligenceQuestion, NonOwnDriverQuestion } from '../Questions';
import { RenderIf } from '../RenderIf';

export const PrivateLiabilityExtraQuestions: React.FC = () => {
  const handleChange = useHandleFieldChange();
  const dispatch = useDispatch();
  const fields = useGetInquiryFields() as InternalInquiryState;
  const errors = useGetInquiryErrors() as InternalInquiryErrorState;

  const handleNonOwnerDriverChange = (newValue: boolean) => {
    let nextField = Field.DateOfBirth;
    if (fields.theftNotAtHome) {
      nextField = Field.TheftNotAtHomeAmount;
    } else if (fields.selfInflictedDamages) {
      nextField = Field.SelfInflictedDamagesAmount;
    }
    handleChange(Field.NonOwnerDriverInsurance, newValue, nextField);
    dispatch(setHouseholdFieldError(Field.NonOwnerDriverInsurance, false));
  };

  const handleGrossNegligenceChange = (newValue: boolean) => {
    handleChange(Field.GrossNegligence, newValue, Field.NonOwnerDriverInsurance);
    dispatch(setHouseholdFieldError(Field.GrossNegligence, false));
  };

  return (
    <>
      <GrossNegligenceQuestion
        error={errors.grossNegligence}
        onChange={handleGrossNegligenceChange}
        currentValue={fields.grossNegligence}
      />
      <RenderIf condition={typeof fields.grossNegligence === 'boolean'}>
        <NonOwnDriverQuestion
          error={errors.nonOwnerDriverInsurance}
          onChange={handleNonOwnerDriverChange}
          currentValue={fields.nonOwnerDriverInsurance}
        />
      </RenderIf>
    </>
  );
};
