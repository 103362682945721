import { useGoogleTagManager } from '@scout24ch/fs24-gtm-react';
import * as React from 'react';
import { gtmEvents } from '../../../config/gtm/events/household';
import { Field, HouseholdType } from '../../../enums';
import { useGetInquiryErrors, useGetInquiryFields, useHandleFieldChange } from '../../../hooks';
import { fetchInsuranceSum } from '../../../state/inquiry/inquiry.actionCreators';
import { InternalInquiryState } from '../../../state/inquiry/inquiry.reducer';
import { setHouseholdFieldError } from '../../../state/inquiryError/inquiryError.actionCreators';
import { InternalInquiryErrorState } from '../../../state/inquiryError/inquiryError.reducer';
import { useDispatch } from '../../../state/rootReducer';
import { hasValue } from '../../householdInsuranceValidators';
import {
  HouseholdOccupancyQuestion,
  HouseholdTypeQuestion,
  IsGroundFloorQuestion,
  IsMassiveQuestion,
  NumberOfRoomsQuestion,
  PossessionTypeQuestion,
  PostalCodeQuestion,
} from '../../Questions';
import { RenderIf } from '../../RenderIf';

export const HouseholdPropertySection: React.FC = () => {
  const fields = useGetInquiryFields() as InternalInquiryState;
  const errors = useGetInquiryErrors() as InternalInquiryErrorState;
  const dispatch = useDispatch();
  const handleChange = useHandleFieldChange();
  const gtm = useGoogleTagManager();

  React.useEffect(() => {
    if (!fields || !fields.possessionType) {
      gtm.push(gtmEvents.householdInsurance.inquiryPage.reached.propertyAndLocation(true));
    }
    // should only happen on mount
    //eslint-disable-next-line
  }, []);

  const shouldRenderIsMassive =
    (hasValue(fields.city.postalCode) && hasValue(fields.city.name) && hasValue(fields.city.canton)) ||
    hasValue(fields.isMassive);

  const shouldRenderHouseholdOccupancyQuestion =
    fields.householdType === HouseholdType.DetachedHouse ||
    (fields.householdType == HouseholdType.Apartment && hasValue(fields.isGroundFloorApartment)) ||
    hasValue(fields.householdOccupancyType);

  const handleHouseholdTypeChange = (newValue: HouseholdType) => {
    if (newValue === HouseholdType.DetachedHouse) {
      handleChange(Field.HouseholdType, newValue, Field.HouseholdOccupancyType);
      handleChange(Field.IsGroundFloorApartment, null);
    } else {
      handleChange(Field.HouseholdType, newValue, Field.IsGroundFloorApartment);
    }
  };

  return (
    <>
      <PossessionTypeQuestion
        error={errors.possessionType}
        onChange={(newValue) => handleChange(Field.PossessionType, newValue, Field.HouseholdType)}
        currentValue={fields.possessionType}
      />
      <RenderIf condition={hasValue(fields.possessionType)}>
        <HouseholdTypeQuestion
          error={errors.householdType}
          onChange={handleHouseholdTypeChange}
          currentValue={fields.householdType}
        />
      </RenderIf>

      <RenderIf condition={fields.householdType === HouseholdType.Apartment}>
        <IsGroundFloorQuestion
          error={errors.isGroundFloorApartment}
          onChange={(newValue) => handleChange(Field.IsGroundFloorApartment, newValue, Field.HouseholdOccupancyType)}
          currentValue={fields.isGroundFloorApartment}
        />
      </RenderIf>

      <RenderIf condition={shouldRenderHouseholdOccupancyQuestion}>
        <HouseholdOccupancyQuestion
          error={false}
          onChange={(newValue) => handleChange(Field.HouseholdOccupancyType, newValue, Field.PostalCode)}
          currentValue={fields.householdOccupancyType}
        />
      </RenderIf>

      <RenderIf condition={hasValue(fields.householdOccupancyType)}>
        <PostalCodeQuestion
          city={fields.city}
          onChange={(newValue) => handleChange(Field.City, newValue, Field.IsMassive)}
          error={errors.city}
          onError={(error) => dispatch(setHouseholdFieldError(Field.City, error))}
          nextField={Field.IsMassive}
        />
      </RenderIf>

      <RenderIf condition={shouldRenderIsMassive}>
        <IsMassiveQuestion
          error={errors.isMassive}
          onChange={(newValue) => handleChange(Field.IsMassive, newValue, Field.NumberOfRooms)}
          currentValue={fields.isMassive}
        />
      </RenderIf>
      <RenderIf condition={hasValue(fields.isMassive)}>
        <NumberOfRoomsQuestion
          error={errors.numberOfRooms}
          onChange={(newValue: string) => {
            handleChange(Field.NumberOfRooms, newValue, Field.Furnishing);
            dispatch(fetchInsuranceSum());
          }}
          currentValue={fields.numberOfRooms}
        />
      </RenderIf>
    </>
  );
};
