import { ECommerceEventLabel, generateEmailHash, gtmProductImpression } from '@scout24ch/fs24-gtm-react';
import { getCurrentDeviceType } from '@scout24ch/fs24-utils';
import { SaveComparisonPosition } from '../../../enums';
import { QuoteDto } from '../../../types/Responses';
import { gtmConsts, myGtmEvents } from '../consts';
import { DataLayerGtmEvent, getImpressionslist, staticGtmEventProps } from '../helpers';

export const gtmEvents = {
  householdInsurance: {
    inquiryPage: {
      reached: {
        propertyAndLocation: (nonInteraction?: true): DataLayerGtmEvent => {
          const dataLayer: DataLayerGtmEvent = {
            ...staticGtmEventProps.householdInsurance,
            eventLabel: 'Property_and_Location',
          };
          if (nonInteraction) {
            dataLayer.nonInteraction = nonInteraction;
          }
          return dataLayer;
        },
        furnishingAndProperty: (nonInteraction?: true): DataLayerGtmEvent => {
          const dataLayer: DataLayerGtmEvent = {
            ...staticGtmEventProps.householdInsurance,
            eventLabel: 'Furnishing_and_Property_Items',
          };
          if (nonInteraction) {
            dataLayer.nonInteraction = nonInteraction;
          }
          return dataLayer;
        },
        additionalInsurances: (nonInteraction?: true): DataLayerGtmEvent => {
          const dataLayer: DataLayerGtmEvent = {
            ...staticGtmEventProps.householdInsurance,
            eventLabel: 'Additional_Insurances_Birthday',
          };
          if (nonInteraction) {
            dataLayer.nonInteraction = nonInteraction;
          }
          return dataLayer;
        },
        insuredPersons: (nonInteraction?: true): DataLayerGtmEvent => {
          const dataLayer: DataLayerGtmEvent = {
            ...staticGtmEventProps.householdInsurance,
            eventLabel: 'Insured_Persons',
          };
          if (nonInteraction) {
            dataLayer.nonInteraction = nonInteraction;
          }
          return dataLayer;
        },
        insuranceSumAndStart: (nonInteraction?: true): DataLayerGtmEvent => {
          const dataLayer: DataLayerGtmEvent = {
            ...staticGtmEventProps.householdInsurance,
            eventLabel: 'Insurance_Sum_and_Start',
          };
          if (nonInteraction) {
            dataLayer.nonInteraction = nonInteraction;
          }
          return dataLayer;
        },
        receivedCompensations: (nonInteraction?: true): DataLayerGtmEvent => {
          const dataLayer: DataLayerGtmEvent = {
            ...staticGtmEventProps.householdInsurance,
            eventLabel: 'Received_Compensations',
          };
          if (nonInteraction) {
            dataLayer.nonInteraction = nonInteraction;
          }
          return dataLayer;
        },
      },
    },
    resultPage: {
      submitComparisonModal: (email: string, buttonPosition: SaveComparisonPosition): DataLayerGtmEvent => ({
        event: myGtmEvents.GTM_EVENT,
        eventCategory: gtmConsts.eventCategory,
        eventAction: 'Submit_Save_Comparison',
        eventLabel: buttonPosition,
        nonInteraction: undefined,
        user: generateEmailHash(email),
      }),
      openSaveComparisonModal: (buttonPosition: SaveComparisonPosition): DataLayerGtmEvent => ({
        event: myGtmEvents.GTM_EVENT,
        eventCategory: gtmConsts.eventCategory,
        eventAction: 'Open_Save_Comparison',
        eventLabel: buttonPosition,
        nonInteraction: undefined,
      }),
      changeSort: (oldSortValue: string, newSortValue: string): DataLayerGtmEvent => ({
        event: myGtmEvents.GTM_EVENT,
        eventCategory: gtmConsts.eventCategory,
        eventAction: 'Change_Sort_Order',
        eventLabel: `valueFrom: ${oldSortValue} # valueTo: ${newSortValue}`,
        nonInteraction: undefined,
      }),
      quickAdjust: {
        recalculate: (
          theftNotAtHome: boolean,
          luggageInsurance: boolean,
          cyber: boolean,
          glassFurniture: boolean,
          selfInflictedDamages: boolean,
          inquiry,
          //eslint-disable-next-line
        ): DataLayerGtmEvent => ({
          event: myGtmEvents.GTM_EVENT,
          eventCategory: gtmConsts.eventCategory,
          eventAction: 'Start_Recalculation',
          eventLabel: `theftNotAtHome: ${theftNotAtHome}, luggageInsurance: ${luggageInsurance}, cyber: ${cyber}, glassFurniture: ${glassFurniture}, selfInflictedDamages: ${selfInflictedDamages}`,
          nonInteraction: undefined,
          inquiry,
        }),
        backToFunnel: (): DataLayerGtmEvent => ({
          event: myGtmEvents.GTM_EVENT,
          eventCategory: gtmConsts.eventCategory,
          eventAction: 'Modify_Data',
          eventLabel: getCurrentDeviceType(),
          nonInteraction: undefined,
        }),
      },
      selectInsuranceToCompare: (insuranceName: string, productName: string): DataLayerGtmEvent => ({
        event: myGtmEvents.GTM_EVENT,
        eventCategory: gtmConsts.eventCategory,
        eventAction: 'Select_Insurance_To_Compare',
        nonInteraction: undefined,
        eventLabel: `${insuranceName} ${[productName]}`,
      }),
      compareInsurances: (): DataLayerGtmEvent => ({
        event: myGtmEvents.GTM_EVENT,
        eventCategory: gtmConsts.eventCategory,
        eventAction: 'Compare_Insurances',
        eventLabel: 'Detail_Comparison',
        nonInteraction: undefined,
      }),
      productImpression: (premiums: readonly QuoteDto[], sortOrder) => {
        const impressions = getImpressionslist(premiums, sortOrder, undefined, false);
        return gtmProductImpression({ eventLabel: ECommerceEventLabel.HOUSEHOLD_INSURANCE, impressions });
      },
    },
    offerPage: {
      requestOfferSent: (insuranceName: string): DataLayerGtmEvent => ({
        event: myGtmEvents.GTM24_EVENT,
        eventCategory: gtmConsts.eventCategory,
        eventAction: 'Request_Offer_Sent',
        nonInteraction: undefined,
        eventLabel: `Insurance: ${insuranceName}`,
      }),
    },
    directDealPage: {
      requestInsurancePolicySent: (insuranceName: string): DataLayerGtmEvent => ({
        event: myGtmEvents.GTM24_EVENT,
        eventCategory: gtmConsts.eventCategory,
        eventAction: 'Request_Insurance_Policy_Sent',
        nonInteraction: undefined,
        eventLabel: `Insurance: ${insuranceName}`,
      }),
    },
  },
};
