import * as React from 'react';
import { Spinner, Flex } from '@scout24ch/fs24-design-system';
import { PlaceholderPage } from '../PlaceholderPage';
import { useTranslations } from '../../hooks';

export const LoadingIndicator = () => {
  const t = useTranslations();
  return (
    <PlaceholderPage showSpinner={false}>
      <span>{t('loadingText')}</span>
      <Flex mt={10} justify="center ">
        <Spinner color="aqua.500" />
      </Flex>
    </PlaceholderPage>
  );
};
