import * as React from 'react';
import { validateEmail, validateRequired } from '@scout24ch/fs24-forms';
import { useGoogleTagManager } from '@scout24ch/fs24-gtm-react';
import { gtmEvents } from '../../../config/gtm/events/household';
import {
  useGetInquiryErrors,
  useGetInquiryFields,
  useHandleFieldChange,
  useTranslations,
  useUser,
} from '../../../hooks';
import { setInitialDamagesArray } from '../../../state/inquiry/inquiry.actionCreators';
import { getHasSingleResident } from '../../../state/inquiry/inquiry.selectors';
import { setHouseholdFieldError } from '../../../state/inquiryError/inquiryError.actionCreators';
import { useDispatch, useSelector } from '../../../state/rootReducer';
import { DamageType, Field } from '../../../enums';
import { asInt } from '../../../utils';
import { DamageDetails } from '../../DamageDetails/DamageDetails';
import {
  CompensationForHouseholdQuestion,
  CompensationForPrivateLiabilityQuestion,
  ContractTerminatedByPreviousInsurerQuestion,
  EmailAddressQuestion,
  InsuranceStartDateQuestion,
  NumberOfHouseholdCompensationsQuestion,
  NumberOfPrivateLiabilityCompensationsQuestion,
  PreviousInsurerQuestion,
  ReasonForCancellationQuestion,
} from '../../Questions';
import { SingleChoiceSelectionOption } from '../../QuestionTypes';
import { RenderIf } from '../../RenderIf';
import { InsuranceSum } from '../InsuranceSum/InsuranceSum';
import { InternalInquiryState } from '../../../state/inquiry/inquiry.reducer';
import { InternalInquiryErrorState } from '../../../state/inquiryError/inquiryError.reducer';

export const DamagesSection: React.FC = () => {
  const t = useTranslations();
  const fields = useGetInquiryFields() as InternalInquiryState;
  const errors = useGetInquiryErrors() as InternalInquiryErrorState;
  const { isUserLoggedIn } = useUser();
  const hasSingleResident = useSelector(getHasSingleResident);
  const handleChange = useHandleFieldChange();
  const gtm = useGoogleTagManager();

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (fields.previousInsurer !== null && fields.hasReceivedCompensationForHousehold === null) {
      gtm.push(gtmEvents.householdInsurance.inquiryPage.reached.receivedCompensations());
    }
  }, [fields, gtm]);

  const handleContractTerminatedByPrevInsurerChange = (newValue: boolean) => {
    if (newValue) {
      handleChange(Field.ContractTerminatedByPreviousInsurer, newValue, Field.ReasonForCancellation);
    } else {
      handleChange(Field.ReasonForCancellation, null);
      handleChange(Field.ContractTerminatedByPreviousInsurer, newValue, 'submit-button');
    }
  };

  const handleNumberOfPrivateLiabilityCompensationsChange = (newValue: SingleChoiceSelectionOption) => {
    handleChange(
      Field.NumberOfPrivateLiabilityDamageCompensations,
      asInt(newValue),
      `${Field.PrivateLiabilityDamages}-reason-0`,
    );
    dispatch(setInitialDamagesArray(asInt(newValue), DamageType.PrivateLiability));
  };

  const handleCompensationForPrivateLiabilityChange = (newValue: boolean) => {
    if (!newValue) {
      dispatch(setInitialDamagesArray(0, DamageType.PrivateLiability));
      handleChange(Field.NumberOfPrivateLiabilityDamageCompensations, null);
    }
    handleChange(
      Field.HasReceivedCompensationForPrivateLiability,
      newValue,
      newValue ? Field.NumberOfPrivateLiabilityDamageCompensations : Field.ContractTerminatedByPreviousInsurer,
    );
  };

  const handleHasReceviedCompensationForHouseholdChange = (newValue: boolean) => {
    let nextField = '';
    if (newValue) {
      nextField = Field.NumberOfHouseholdDamageCompensations;
    } else if (!newValue && fields.privateLiability) {
      nextField = Field.HasReceivedCompensationForPrivateLiability;
      dispatch(setInitialDamagesArray(0, DamageType.Household));
      handleChange(Field.NumberOfHouseholdDamageCompensations, null);
    } else {
      nextField = Field.ContractTerminatedByPreviousInsurer;
      handleChange(Field.NumberOfHouseholdDamageCompensations, null);
      dispatch(setInitialDamagesArray(0, DamageType.Household));
    }
    handleChange(Field.HasReceivedCompensationForHousehold, newValue, nextField);
  };

  const handleNumberOfHouseholdDamageCompensationsChange = (newValue: SingleChoiceSelectionOption) => {
    handleChange(Field.NumberOfHouseholdDamageCompensations, asInt(newValue), `${Field.HouseholdDamages}-reason-0`);
    dispatch(setInitialDamagesArray(asInt(newValue), DamageType.Household));
  };
  const shouldRenderSection =
    fields.previousInsurer !== null ||
    (fields.isDogOwner && fields.numberOfDogs !== null) ||
    fields.isDogOwner === false ||
    (!fields.privateLiability && hasSingleResident) ||
    (!fields.privateLiability &&
      ((fields.numberOfResidentsAgedUnder16 !== null &&
        fields.childrenDatesOfBirth[asInt(fields.numberOfResidentsAgedUnder16) - 1] !== null &&
        fields.childrenDatesOfBirth[asInt(fields.numberOfResidentsAgedUnder16) - 1] !== '' &&
        errors.childrenDatesOfBirth[asInt(fields.numberOfResidentsAgedUnder16) - 1] === null) ||
        asInt(fields.numberOfResidentsAgedUnder16) === 0));

  const shouldRenderContractTerminatedByPreinsurer = () => {
    if (fields.contractTerminatedByPreviousInsurer !== null) {
      return true;
    }
    if (fields.privateLiability) {
      if (fields.hasReceivedCompensationForPrivateLiability === false) {
        return true;
      }
      return (
        fields.hasReceivedCompensationForPrivateLiability === true &&
        typeof fields.numberOfPrivateLiabilityDamageCompensations === 'number' &&
        fields.privateLiabilityDamages[fields.numberOfPrivateLiabilityDamageCompensations - 1] &&
        fields.privateLiabilityDamages[fields.numberOfPrivateLiabilityDamageCompensations - 1].month !== null
      );
    } else {
      if (fields.hasReceivedCompensationForHousehold === false) {
        return true;
      }
      return (
        fields.hasReceivedCompensationForHousehold === true &&
        typeof fields.numberOfHouseholdDamageCompensations === 'number' &&
        fields.householdDamages[fields.numberOfHouseholdDamageCompensations - 1] &&
        fields.householdDamages[fields.numberOfHouseholdDamageCompensations - 1].month !== null
      );
    }
  };

  const shouldRenderComensationForPrivateLiability =
    fields.hasReceivedCompensationForPrivateLiability !== null ||
    (fields.privateLiability && fields.hasReceivedCompensationForHousehold === false) ||
    (fields.privateLiability &&
      fields.householdDamages[(fields.numberOfHouseholdDamageCompensations as number) - 1] &&
      fields.householdDamages[(fields.numberOfHouseholdDamageCompensations as number) - 1].month !== null);

  return (
    <>
      <RenderIf condition={shouldRenderSection}>
        <InsuranceSum />
        <h3 className="inquiry-section-title">{t('damagesSectionTitle')}</h3>
        <RenderIf condition={!isUserLoggedIn}>
          <EmailAddressQuestion
            error={errors.emailAddress}
            currentValue={fields.emailAddress}
            onValueChange={(newValue) => handleChange(Field.EmailAddress, newValue)}
            onBlur={() => {
              const error = validateEmail(fields.emailAddress!) || validateRequired(fields.emailAddress!);
              dispatch(setHouseholdFieldError(Field.EmailAddress, error));
            }}
          />
        </RenderIf>
        <InsuranceStartDateQuestion
          onChange={(newDate) => handleChange(Field.InsuranceStartDate, newDate)}
          onError={(error) => dispatch(setHouseholdFieldError(Field.InsuranceStartDate, error))}
          currentValue={fields.insuranceStartDate}
          error={errors.insuranceStartDate}
        />
        <PreviousInsurerQuestion
          error={errors.previousInsurer}
          currentValue={fields.previousInsurer}
          onChange={(newValue: string) =>
            handleChange(Field.PreviousInsurer, newValue, Field.HasReceivedCompensationForHousehold)
          }
        />
      </RenderIf>
      <RenderIf condition={fields.previousInsurer !== null}>
        <CompensationForHouseholdQuestion
          error={errors.hasReceivedCompensationForHousehold}
          onChange={handleHasReceviedCompensationForHouseholdChange}
          currentValue={fields.hasReceivedCompensationForHousehold}
        />
      </RenderIf>
      <RenderIf condition={fields.hasReceivedCompensationForHousehold === true}>
        <NumberOfHouseholdCompensationsQuestion
          error={errors.numberOfHouseholdDamageCompensations}
          currentValue={fields.numberOfHouseholdDamageCompensations}
          onChange={handleNumberOfHouseholdDamageCompensationsChange}
        />
      </RenderIf>
      <RenderIf condition={fields.numberOfHouseholdDamageCompensations !== null}>
        <DamageDetails
          numberOfDamages={fields.numberOfHouseholdDamageCompensations as number}
          name={Field.HouseholdDamages}
          nextField={
            fields.privateLiability
              ? Field.HasReceivedCompensationForPrivateLiability
              : Field.ContractTerminatedByPreviousInsurer
          }
        />
      </RenderIf>
      <RenderIf condition={shouldRenderComensationForPrivateLiability}>
        <CompensationForPrivateLiabilityQuestion
          error={errors.hasReceivedCompensationForPrivateLiability}
          onChange={handleCompensationForPrivateLiabilityChange}
          currentValue={fields.hasReceivedCompensationForPrivateLiability}
        />
      </RenderIf>
      <RenderIf condition={fields.hasReceivedCompensationForPrivateLiability === true}>
        <NumberOfPrivateLiabilityCompensationsQuestion
          error={errors.numberOfPrivateLiabilityDamageCompensations}
          currentValue={fields.numberOfPrivateLiabilityDamageCompensations}
          onChange={handleNumberOfPrivateLiabilityCompensationsChange}
        />
      </RenderIf>
      <RenderIf
        condition={
          fields.numberOfPrivateLiabilityDamageCompensations !== null || fields.privateLiabilityDamages.length > 0
        }
      >
        <DamageDetails
          numberOfDamages={fields.numberOfPrivateLiabilityDamageCompensations as number}
          name={Field.PrivateLiabilityDamages}
          nextField={Field.ContractTerminatedByPreviousInsurer}
        />
      </RenderIf>
      <RenderIf condition={shouldRenderContractTerminatedByPreinsurer()}>
        <ContractTerminatedByPreviousInsurerQuestion
          error={errors.contractTerminatedByPreviousInsurer}
          onChange={handleContractTerminatedByPrevInsurerChange}
          currentValue={fields.contractTerminatedByPreviousInsurer}
        />
      </RenderIf>
      <RenderIf condition={fields.contractTerminatedByPreviousInsurer === true}>
        <ReasonForCancellationQuestion
          error={errors.reasonForCancellation}
          currentValue={fields.reasonForCancellation}
          onChange={(newValue: string) => {
            handleChange(Field.ReasonForCancellation, newValue, 'submit-button');
          }}
        />
      </RenderIf>
    </>
  );
};
