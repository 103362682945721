import * as React from 'react';
import { RadioGroupQuestion } from '@scout24ch/fs24-design-system';

export type SingleChoiceSelectionOption = string | boolean | number | null;
export interface SingleChoiceSelectionProps {
  errorMessage?: string;
  title: string;
  intro?: string;
  name: string;
  options: {
    value: SingleChoiceSelectionOption;
    label: string;
    icon?: React.ReactNode;
  }[];
  currentValue: any;
  onChange(newValue: any): void;
}

export const SingleChoiceSelection: React.FC<SingleChoiceSelectionProps> = (props) => (
  <>
    <RadioGroupQuestion
      label={props.title}
      description={props.intro}
      errorMessage={props.errorMessage}
      name={props.name}
      onChange={props.onChange}
      options={props.options}
      value={props.currentValue}
    />

    <div className="scroll-checkpoint" data-scroll={props.name} />
  </>
);
