import { NextPage } from 'next';
import * as React from 'react';
import { getInquiry } from '../../src/api/inquiry';
import { COOKIE_LAST_INQUIRY_ID } from '../../src/common/constants';
import { HouseholdInquiryPage } from '../../src/Household/HouseholdInquiryPage/HouseholdInquiryPage';
import { setInquiryFields } from '../../src/state/inquiry/inquiry.actionCreators';
import { mapResponseToHouseholdReduxInquiry } from '../../src/mappers/householdInquirymapper';
import { ConfiguredNextContext } from '../../src/types/next';

export interface InquiryPageContext extends ConfiguredNextContext {
  req?: ConfiguredNextContext['req'] & {
    cookies?: {
      [key: string]: string;
    };
  };
}

const Inquiry: NextPage = () => {
  return <HouseholdInquiryPage />;
};

Inquiry.getInitialProps = async ({ req, store }: InquiryPageContext) => {
  const inquiryId = req?.cookies?.[COOKIE_LAST_INQUIRY_ID];
  if (inquiryId) {
    const inquiryData = await getInquiry(inquiryId);
    const mappedInquiry = mapResponseToHouseholdReduxInquiry(inquiryData);
    store.dispatch(setInquiryFields(mappedInquiry));
  }
};

export default Inquiry;
