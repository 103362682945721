import { range } from '@scout24ch/fs24-utils';
import * as React from 'react';
import { Field } from '../../../enums';
import { useGetInquiryErrors, useGetInquiryFields, useHandleFieldChange } from '../../../hooks';
import {
  fetchInsuranceSum,
  setChildDateOfBirth,
  updateChildrenDatesOfBirth,
} from '../../../state/inquiry/inquiry.actionCreators';
import { InternalInquiryState } from '../../../state/inquiry/inquiry.reducer';
import {
  setDateError,
  setHouseholdFieldError,
  updateDatesArray,
} from '../../../state/inquiryError/inquiryError.actionCreators';
import { InternalInquiryErrorState } from '../../../state/inquiryError/inquiryError.reducer';
import { useDispatch } from '../../../state/rootReducer';
import { asInt } from '../../../utils/asInt';
import { hasValue } from '../../householdInsuranceValidators';
import { ChildrenBirthDatesQuestion, NumberOfChildrenQuestion, NumberOfInsureesQuestion } from '../../Questions';
import { SingleChoiceSelectionOption } from '../../QuestionTypes';
import { RenderIf } from '../../RenderIf';

interface InsureesQuestionsProps {
  hasPrivateLiability: boolean;
}

export const InsureesQuestions: React.FC<InsureesQuestionsProps> = (props) => {
  const fields = useGetInquiryFields() as InternalInquiryState;
  const errors = useGetInquiryErrors() as InternalInquiryErrorState;
  const handleChange = useHandleFieldChange();
  const dispatch = useDispatch();

  const handleNumberOfInsureesChange = (newValue: SingleChoiceSelectionOption) => {
    if (asInt(newValue) === 1) {
      const nextField = props.hasPrivateLiability ? Field.IsDogOwner : Field.PreviousInsurer;
      handleChange(Field.NumberOfInsurees, newValue, nextField);
      handleChange(Field.NumberOfResidentsAgedUnder16, null);
      handleChange(Field.ChildrenDatesOfBirth, []);
      dispatch(setHouseholdFieldError(Field.ChildrenDatesOfBirth, []));
      dispatch(fetchInsuranceSum());
    } else {
      const numberOfChildren = asInt(fields.numberOfResidentsAgedUnder16);
      if (hasValue(fields.numberOfResidentsAgedUnder16) && asInt(newValue) <= numberOfChildren) {
        handleChange(Field.NumberOfResidentsAgedUnder16, '');
      }
      handleChange(Field.NumberOfInsurees, newValue, Field.NumberOfResidentsAgedUnder16);
      dispatch(fetchInsuranceSum());
    }
  };

  const handleNumberOfChildrenChange = (newValue: SingleChoiceSelectionOption) => {
    const numberOfChildren = asInt(newValue);
    let nextField: any = Field.ChildrenDatesOfBirth;
    if (numberOfChildren === 0) {
      nextField = props.hasPrivateLiability ? Field.IsDogOwner : Field.PreviousInsurer;
    }
    handleChange(Field.NumberOfResidentsAgedUnder16, newValue, nextField);
    dispatch(fetchInsuranceSum());
    if (fields.childrenDatesOfBirth.length > 0) {
      dispatch(updateChildrenDatesOfBirth(asInt(newValue)));
      dispatch(updateDatesArray(asInt(newValue)));
    } else {
      const initialErrors = range(numberOfChildren, () => null);
      const initialDates = range(numberOfChildren, () => '');
      handleChange(Field.ChildrenDatesOfBirth, initialDates);
      dispatch(setHouseholdFieldError(Field.ChildrenDatesOfBirth, initialErrors));
    }
  };

  return (
    <>
      <NumberOfInsureesQuestion
        error={errors.numberOfInsurees}
        currentValue={fields.numberOfInsurees}
        onChange={handleNumberOfInsureesChange}
      />

      <RenderIf condition={hasValue(fields.numberOfInsurees) && asInt(fields.numberOfInsurees) !== 1}>
        <NumberOfChildrenQuestion
          error={errors.numberOfResidentsAgedUnder16}
          currentValue={fields.numberOfResidentsAgedUnder16}
          onChange={handleNumberOfChildrenChange}
          numberOfInsurees={fields.numberOfInsurees}
        />
      </RenderIf>
      <RenderIf condition={hasValue(fields.numberOfResidentsAgedUnder16)}>
        <ChildrenBirthDatesQuestion
          numberOfChildren={asInt(fields.numberOfResidentsAgedUnder16)}
          onError={(error, index) => dispatch(setDateError(error, index))}
          onChange={(newValue, index) => dispatch(setChildDateOfBirth(newValue, index))}
          errors={errors.childrenDatesOfBirth}
          currentValues={fields.childrenDatesOfBirth}
          nextField={props.hasPrivateLiability ? Field.IsDogOwner : Field.PreviousInsurer}
        />
      </RenderIf>
    </>
  );
};
