import * as React from 'react';
import { Stepper, Container } from '@scout24ch/fs24-design-system';
import { Step } from '../../enums';
import { useTranslations } from '../../hooks';

interface StepNavigationProps {
  currentStep: Step;
  steps?: any[];
}

export const StepNavigation: React.FC<StepNavigationProps> = (props) => {
  const t = useTranslations();

  const steps = [
    { id: 'general', label: t('general'), resolved: true },
    { id: 'comparison', label: t('comparison'), resolved: false },
    { id: 'insure', label: t('insure'), resolved: false },
  ];

  const currentIndex = steps?.findIndex((s) => s.id === props.currentStep) + 1 || 1;

  return (
    <Container mb={12}>
      <Stepper currentStep={currentIndex ?? 1} stepLabels={steps.map((step) => step.label)} />
    </Container>
  );
};
