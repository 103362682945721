import * as React from 'react';
import { UiCloseIcon } from '@scout24ch/fs24-ui';
import { Button } from '@scout24ch/fs24-design-system';
import { useRouter } from 'next/router';
import ClientOnlyPortal from '../../ClientOnlyPortal/ClientOnlyPortal';
import { RenderIf } from '../RenderIf';

export interface ModalProps {
  onClose: () => void;
  isOpen: boolean;
  isSmall?: boolean;
  bannerImage?: string;
  closeLabel?: string;
  children?: React.ReactNode;
}

const modalCleanUp = () => {
  if (typeof window !== 'undefined') {
    document.body.style.position = '';
    document.body.style.top = '';
    document.body.style.overflow = '';
  }
};

export const Modal = (props: ModalProps) => {
  const router = useRouter();

  const handleClose = () => {
    props.onClose();
    modalCleanUp();
  };

  React.useEffect(() => {
    router.events.on('routeChangeStart', handleClose);

    return () => {
      router.events.off('routeChangeStart', handleClose);
      handleClose();
    };
    // should only happen on mount
    //eslint-disable-next-line
  }, []);
  return (
    <>
      {props.isOpen && (
        <ClientOnlyPortal selector="#modal">
          <div className="backdrop">
            <div className={`modal modal${props.isSmall ? '__is-small' : ''}`}>
              <div className="m-modal__header">
                <Button className="m-modal__close-button" variant="link" onClick={handleClose}>
                  <UiCloseIcon height="12" width="12" />
                  {props.closeLabel && <span>{props.closeLabel}</span>}
                </Button>
              </div>
              <div className="m-modal__content">
                <RenderIf condition={!!props.bannerImage}>
                  <img src={props.bannerImage} alt="sofa" />
                </RenderIf>
                {props.children}
              </div>
            </div>
          </div>
        </ClientOnlyPortal>
      )}
    </>
  );
};
