import { convertToISOFormat } from '@scout24ch/fs24-utils';
import { Field, HouseholdType } from '../enums';
import { Claim } from '../types/Requests';

export const stringifyDamages = (damages) => {
  return damages.map((damage) => ({
    ...damage,
    year: String(damage.year),
    month: String(damage.month),
    amount: String(damage.amount),
  }));
};

export const mapResponseToHouseholdReduxInquiry = (response) => {
  const { damages, personalDetails } = response;

  const householdDamages = damages.claims.filter((damage: Claim) => damage.type === 'household');
  const privateLiabilityDamages = damages.claims.filter((damage: Claim) => damage.type === 'privateLiability');

  return {
    ...response.householdProperty,
    [Field.IsGroundFloorApartment]:
      response.householdProperty.householdType === HouseholdType.Apartment
        ? response.householdProperty.isGroundFloorApartment
        : null,
    [Field.City]: { ...response.householdProperty.city },
    ...response.householdContent,
    [Field.DateOfBirth]: convertToISOFormat(response.personalDetails.dateOfBirth),
    [Field.EmailAddress]: personalDetails.emailAddress,
    [Field.IsDogOwner]: response.coverage.privateLiability ? personalDetails.numberOfDogs > 0 : null,
    [Field.NumberOfDogs]:
      response.coverage.privateLiability && personalDetails.numberOfDogs > 0
        ? String(personalDetails.numberOfDogs)
        : null,
    [Field.NumberOfInsurees]: String(personalDetails.numberOfInsurees),
    [Field.NumberOfResidentsAgedUnder16]:
      personalDetails.numberOfInsurees > 1 ? String(personalDetails.children.length) : null,
    [Field.ChildrenDatesOfBirth]: personalDetails.children.map((child) => convertToISOFormat(child.dateOfBirth)),
    [Field.GrossNegligence]: response.grossNegligence,
    [Field.NonOwnerDriverInsurance]: response.nonOwnerDriverInsurance,
    [Field.SelfInflictedDamagesAmount]: response.coverage.selfInflictedDamagesAmount,
    [Field.TheftNotAtHomeAmount]: response.coverage.theftNotAtHomeAmount,
    [Field.PrivateLiability]: response.coverage.privateLiability,
    [Field.TheftNotAtHome]: response.coverage.theftNotAtHome,
    [Field.LuggageInsurance]: response.coverage.luggageInsurance,
    [Field.CyberInsurance]: response.coverage.cyberInsurance,
    [Field.GlassFurniture]: response.coverage.glassFurniture,
    [Field.SelfInflictedDamages]: response.coverage.selfInflictedDamages,
    //TODO_remove-in-future
    goBackToFunnel: response.openFunnel,
    [Field.InsuranceStartDate]: convertToISOFormat(response.insuranceStartDate),
    [Field.InsuranceSum]: response.insuranceSum || 0,
    identifier: response.identifier,
    [Field.RecommendedInsuranceSum]: response.recommendedInsuranceSum || 0,
    [Field.ReasonForCancellation]: damages.reasonForCancellation,
    [Field.HasReceivedCompensationForHousehold]: householdDamages.length > 0,
    [Field.HasReceivedCompensationForPrivateLiability]: response.coverage.privateLiability
      ? privateLiabilityDamages.length > 0
      : null,
    [Field.ContractTerminatedByPreviousInsurer]: !!damages.reasonForCancellation,
    [Field.NumberOfHouseholdDamageCompensations]: householdDamages.length === 0 ? null : householdDamages.length,
    [Field.NumberOfPrivateLiabilityDamageCompensations]:
      privateLiabilityDamages.length === 0 ? null : privateLiabilityDamages.length,
    [Field.HouseholdDamages]: stringifyDamages(householdDamages),
    [Field.PrivateLiabilityDamages]: stringifyDamages(privateLiabilityDamages),
    [Field.PreviousInsurer]: response.previousInsuranceCompany,
    [Field.DataProtectionAccepted]: response.dataProtectionAccepted,
  };
};
