import { logger } from '../common/log/logger';
import { insuranceApiClient } from '../lib/apiClients/insuranceApiClient';

export const getInquiry = async (requestId: string) => {
  try {
    const response = await insuranceApiClient.get(`/quote/inquiry/${requestId}`);
    return response.data;
  } catch (error) {
    logger.error('Unable to fetch inquiry data', error.message);
    throw error;
  }
};
