import React from 'react';
import { DeviceType, getCurrentDeviceType } from '@scout24ch/fs24-utils';
import { useTranslations } from '../../../hooks';
import { Bike, CheckRoom, Diamond, Furniture, MobileAndDesktop } from '../../Icon';
import { Modal, ModalProps } from '../Modal';

export const InsuranceSumModal = (props: ModalProps) => {
  const t = useTranslations();
  return (
    <Modal
      isOpen={props.isOpen}
      closeLabel={t('modalCloseLabel')}
      onClose={props.onClose}
      bannerImage={
        typeof window !== 'undefined' && getCurrentDeviceType() === DeviceType.DESKTOP
          ? '/static/assets/img/insurance-sum-modal-desktop.jpg'
          : '/static/assets/img/insurance-sum-modal.jpg'
      }
    >
      <div className="insurance-sum-modal">
        <h3>{t('modalTitle')}</h3>
        <p className="main-text">{t('modalText')}</p>
        <h4>{t('modalSecondaryText')}</h4>
        <div className="label">
          <Furniture /> {t('furnishing')}
        </div>
        <div className="label">
          <CheckRoom />
          {t('clothing')}
        </div>
        <div className="label">
          <MobileAndDesktop />
          {t('electricAppliances')}
        </div>
        <div className="label">
          <Bike /> {t('sportingEquipment')}
        </div>
        <div className="label">
          <Diamond /> {t('valuables')}
        </div>
      </div>
    </Modal>
  );
};
