import { useGoogleTagManager } from '@scout24ch/fs24-gtm-react';
import * as React from 'react';
import { gtmEvents } from '../../../config/gtm/events/household';
import { DateError, Field } from '../../../enums';
import { useGetInquiryErrors, useGetInquiryFields, useHandleFieldChange, useTranslations } from '../../../hooks';
import { InternalInquiryState } from '../../../state/inquiry/inquiry.reducer';
import { getHasSingleResident } from '../../../state/inquiry/inquiry.selectors';
import { setHouseholdFieldError } from '../../../state/inquiryError/inquiryError.actionCreators';
import { InternalInquiryErrorState } from '../../../state/inquiryError/inquiryError.reducer';
import { useDispatch, useSelector } from '../../../state/rootReducer';
import { asInt } from '../../../utils/asInt';
import { hasValue } from '../../householdInsuranceValidators';
import { DateOfBirthQuestion } from '../../Questions/DateOfBirthQuestion';
import { RenderIf } from '../../RenderIf';
import { DogQuestions } from './DogQuestions';
import { InsureesQuestions } from './InsureesQuestions';

export const PersonalSection: React.FC = () => {
  const fields = useGetInquiryFields() as InternalInquiryState;
  const t = useTranslations();
  const errors = useGetInquiryErrors() as InternalInquiryErrorState;
  const hasSingleResident = useSelector(getHasSingleResident);
  const dispatch = useDispatch();
  const handleChange = useHandleFieldChange();
  const gtm = useGoogleTagManager();

  React.useEffect(() => {
    if (!fields || !fields.dateOfBirth) {
      gtm.push(gtmEvents.householdInsurance.inquiryPage.reached.additionalInsurances());
    }
    // should only happen on mount
    //eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (fields.dateOfBirth && fields.dateOfBirth.split('-').length === 3 && hasSingleResident === null) {
      gtm.push(gtmEvents.householdInsurance.inquiryPage.reached.insuredPersons());
    }
  }, [fields, hasSingleResident, gtm]);

  const shouldRenderIsDogOwner =
    (hasSingleResident && fields.privateLiability) ||
    (hasSingleResident === false &&
      hasValue(fields.numberOfInsurees) &&
      hasValue(fields.numberOfResidentsAgedUnder16) &&
      fields.privateLiability &&
      hasValue(fields.childrenDatesOfBirth[asInt(fields.numberOfResidentsAgedUnder16) - 1]) &&
      errors.childrenDatesOfBirth[asInt(fields.numberOfResidentsAgedUnder16) - 1] === null) ||
    (fields.privateLiability && asInt(fields.numberOfResidentsAgedUnder16) === 0);

  const isDateNotFilledEntirely = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length !== 10) {
      dispatch(setHouseholdFieldError(Field.DateOfBirth, DateError.DateOfBirthMissing));
    }
  };

  const handleDateError = (error: DateError | null) => {
    dispatch(setHouseholdFieldError(Field.DateOfBirth, error));
  };

  return (
    <>
      <h3 className="inquiry-section-title">{t('personalSectionTitle')}</h3>
      <DateOfBirthQuestion
        onError={handleDateError}
        onChange={(newValue) => handleChange(Field.DateOfBirth, newValue, Field.NumberOfInsurees)}
        onBlur={isDateNotFilledEntirely}
        error={errors.dateOfBirth}
        currentValue={fields.dateOfBirth}
      />

      <RenderIf condition={errors.dateOfBirth === null && hasValue(fields.dateOfBirth)}>
        <InsureesQuestions hasPrivateLiability={fields.privateLiability} />
      </RenderIf>
      <RenderIf condition={shouldRenderIsDogOwner || hasValue(fields.isDogOwner)}>
        <DogQuestions />
      </RenderIf>
    </>
  );
};
