import * as React from 'react';
import { CurrencyInput, Grid, GridItem, Question } from '@scout24ch/fs24-design-system';
import { DamageReason, Field } from '../../enums';
import { useGetInquiryErrors, useGetInquiryFields, useTranslations } from '../../hooks';
import { setDamageDetailField } from '../../state/inquiry/inquiry.actionCreators';
import { setDamageAmountError } from '../../state/inquiryError/inquiryError.actionCreators';
import { useDispatch, useSelector } from '../../state/rootReducer';
import { scrollTo } from '../../utils';
import { DamageReasonQuestion, MonthOfDamageQuestion, YearOfDamageQuestion } from '../Questions';
import { RenderIf } from '../RenderIf';
import { damageAmountHasError } from '../householdInsuranceValidators';
import { InternalInquiryState } from '../../state/inquiry/inquiry.reducer';
import { InternalInquiryErrorState } from '../../state/inquiryError/inquiryError.reducer';

interface DamageDetailsProps {
  numberOfDamages: number;
  name: string;
  nextField: string;
}

export const DamageDetails: React.FC<DamageDetailsProps> = (props) => {
  const t = useTranslations();
  const fields = useGetInquiryFields() as InternalInquiryState;
  const numberOfCompensations = useSelector((state) => {
    return props.name === Field.HouseholdDamages
      ? state.inquiry.numberOfHouseholdDamageCompensations
      : state.inquiry.numberOfPrivateLiabilityDamageCompensations;
  });

  const errors = useGetInquiryErrors() as InternalInquiryErrorState;
  const dispatch = useDispatch();

  const handleFieldChange = (
    newValue: string,
    damageType: string,
    field: string,
    index: number,
    nextFieldId?: string,
  ) => {
    dispatch(setDamageDetailField(newValue, damageType, field, index));
    if (nextFieldId) {
      scrollTo(nextFieldId);
    }
  };

  const handleYearChange = (newValue: string, index: number) => {
    if (
      fields[props.name][index].month !== null &&
      parseInt(fields[props.name][index].month, 10) > new Date().getMonth() + 1
    ) {
      handleFieldChange('', props.name, DamageReason.Month, index);
    }
    handleFieldChange(newValue, props.name, DamageReason.Year, index, `${props.name}-${DamageReason.Month}-${index}`);
  };

  const handleMonthChange = (newValue: string, index: number) => {
    handleFieldChange(
      newValue,
      props.name,
      DamageReason.Month,
      index,
      index === props.numberOfDamages - 1 ? props.nextField : `${props.name}-${DamageReason.Reason}-${index + 1}`,
    );
  };

  return (
    <>
      {new Array(props.numberOfDamages).fill(undefined).map((damageInfo, index) => (
        <React.Fragment key={index}>
          <RenderIf
            condition={index > 0 ? fields[props.name][index - 1].month !== null : numberOfCompensations !== null}
          >
            <h2 className="damage-title">{props.numberOfDamages > 1 ? `${t('damage')} ${index + 1}` : null}</h2>
            <DamageReasonQuestion
              error={errors[props.name][index] && errors[props.name][index].reason}
              compensationType={props.name}
              currentValue={fields[props.name][index].reason}
              fieldName={`${props.name}-${DamageReason.Reason}-${index}`}
              onChange={(newValue) =>
                handleFieldChange(
                  newValue,
                  props.name,
                  DamageReason.Reason,
                  index,
                  `${props.name}-${DamageReason.Year}-${index}`,
                )
              }
            />
          </RenderIf>
          <RenderIf condition={fields[props.name][index].reason !== null || fields[props.name][index].year !== null}>
            <Question label={t('amountQuestion')} name={`damages${props.name}-${index}-amount`}>
              <Grid templateColumns="repeat(12, 1fr)" gap={6}>
                <GridItem colSpan={6}>
                  <CurrencyInput
                    id={`damages${props.name}-${index}-amount`}
                    name={`damages${props.name}-${index}-amount`}
                    value={fields[props.name][index].amount || ''}
                    onChange={(e) => {
                      handleFieldChange(e.target.value, props.name, DamageReason.Amount, index);
                      dispatch(setDamageAmountError(damageAmountHasError(e.target.value), index, props.name));
                    }}
                  />
                </GridItem>
              </Grid>
            </Question>
            <p className="damage-date">{t('timeOfDamageQuestion')}</p>
            <YearOfDamageQuestion
              error={errors[props.name][index] && errors[props.name][index].year}
              fieldName={`${props.name}-${DamageReason.Year}-${index}`}
              currentValue={fields[props.name][index].year}
              onChange={(newValue) => handleYearChange(newValue, index)}
            />
          </RenderIf>
          <RenderIf condition={fields[props.name][index].year !== null || fields[props.name][index].month !== null}>
            <MonthOfDamageQuestion
              error={errors[props.name][index] && errors[props.name][index].month}
              fieldName={`${props.name}-${DamageReason.Month}-${index}`}
              year={fields[props.name][index].year}
              currentValue={fields[props.name][index].month}
              onChange={(newValue: string) => handleMonthChange(newValue, index)}
            />
          </RenderIf>
        </React.Fragment>
      ))}
    </>
  );
};
