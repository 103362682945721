import * as React from 'react';
import { Badge, Checkbox, Icon, Question } from '@scout24ch/fs24-design-system';
import {
  UsersThree,
  Lock,
  TravelTime,
  ShieldVerified,
  SpaceDwelling,
  Lamp,
} from '@scout24ch/fs24-design-system/icons/24';
import { formatCurrencyToInteger } from '@scout24ch/fs24-utils';
import { DamageType, Field } from '../../../enums';
import { useGetInquiryErrors, useGetInquiryFields, useHandleFieldChange, useTranslations } from '../../../hooks';
import { setInitialDamagesArray } from '../../../state/inquiry/inquiry.actionCreators';
import { useDispatch } from '../../../state/rootReducer';
import { hasValue } from '../../householdInsuranceValidators';
import { PrivateLiabilityExtraQuestions } from '../../PrivateLiabilityExtraQuestions/PrivateLiabilityExtraQuestions';
import { SingleChoiceSelection } from '../../QuestionTypes';
import { RenderIf } from '../../RenderIf';
import { InternalInquiryErrorState } from '../../../state/inquiryError/inquiryError.reducer';
import { InternalInquiryState } from '../../../state/inquiry/inquiry.reducer';

export const CoveragesSection: React.FC = () => {
  const t = useTranslations();
  const fields = useGetInquiryFields() as InternalInquiryState;
  const errors = useGetInquiryErrors() as InternalInquiryErrorState;
  const dispatch = useDispatch();
  const handleChange = useHandleFieldChange();

  const theftNotAtHomeOptions = [
    { value: 1000, label: formatCurrencyToInteger(1000) },
    { value: 2000, label: formatCurrencyToInteger(2000) },
    { value: 3000, label: formatCurrencyToInteger(3000) },
  ];

  const selfInflictedDamagesOptions = [
    { value: 2000, label: formatCurrencyToInteger(2000) },
    { value: 3000, label: formatCurrencyToInteger(3000) },
    { value: 5000, label: formatCurrencyToInteger(5000) },
  ];

  const renderSelfInflictedDamagesAmount = () => {
    if (fields.selfInflictedDamages && hasValue(fields.theftNotAtHomeAmount)) {
      return true;
    }
    if (fields.selfInflictedDamages && hasValue(fields.nonOwnerDriverInsurance)) {
      return true;
    }
    if (fields.selfInflictedDamages && !fields.theftNotAtHome && !fields.privateLiability) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Question name="coveragesTitle" label={t('coveragesTitle')} className="coverages-section">
        <div className="recommended">
          <Badge variant="default" colorScheme="blue">
            {t('recommendedBadge')}
          </Badge>
          <Checkbox
            name={Field.PrivateLiability}
            id={Field.PrivateLiability}
            isChecked={fields.privateLiability}
            py={2}
            alignItems="baseline"
            onChange={(e) => {
              handleChange(Field.PrivateLiability, e.target.checked);
              if (!e.target.checked) {
                handleChange(Field.IsDogOwner, null);
                handleChange(Field.NumberOfDogs, null);
                handleChange(Field.GrossNegligence, null);
                handleChange(Field.NonOwnerDriverInsurance, null);
                handleChange(Field.NumberOfPrivateLiabilityDamageCompensations, null);
                dispatch(setInitialDamagesArray(0, DamageType.PrivateLiability));
                handleChange(Field.HasReceivedCompensationForPrivateLiability, null);
              }
            }}
          >
            <div className="label">
              <Icon as={UsersThree} flexShrink={0} mr={2} w={6} h={6} />
              {t('privateLiabilityLabel')}
            </div>
          </Checkbox>
          <Checkbox
            name={Field.TheftNotAtHome}
            id={Field.TheftNotAtHome}
            isChecked={fields.theftNotAtHome}
            py={2}
            alignItems="baseline"
            onChange={(e) => {
              handleChange(Field.TheftNotAtHome, e.target.checked);
              handleChange(Field.TheftNotAtHomeAmount, null);
            }}
          >
            <div className="label">
              <Icon as={Lock} flexShrink={0} mr={2} w={6} h={6} />
              {t('theftNotAtHomeLabel')}
            </div>
          </Checkbox>
        </div>
        <>
          <Checkbox
            name={Field.LuggageInsurance}
            id={Field.LuggageInsurance}
            isChecked={fields.luggageInsurance}
            py={2}
            alignItems="baseline"
            onChange={(e) => handleChange(Field.LuggageInsurance, e.target.checked)}
          >
            <div className="label">
              <Icon as={TravelTime} flexShrink={0} mr={2} w={6} h={6} />
              {t('luggageInsuranceLabel')}
            </div>
          </Checkbox>
          <Checkbox
            name={Field.CyberInsurance}
            id={Field.CyberInsurance}
            isChecked={fields.cyberInsurance}
            py={2}
            alignItems="baseline"
            onChange={(e) => handleChange(Field.CyberInsurance, e.target.checked)}
          >
            <div className="label">
              <Icon as={ShieldVerified} flexShrink={0} mr={2} w={6} h={6} />
              {t('cyberInsuranceLabel')}
            </div>
          </Checkbox>
          <Checkbox
            name={Field.GlassFurniture}
            id={Field.GlassFurniture}
            isChecked={fields.glassFurniture}
            py={2}
            alignItems="baseline"
            onChange={(e) => handleChange(Field.GlassFurniture, e.target.checked)}
          >
            <div className="label">
              <Icon as={SpaceDwelling} flexShrink={0} mr={2} w={6} h={6} />
              {t('glassFurnitureLabel')}
            </div>
          </Checkbox>
          <Checkbox
            name={Field.SelfInflictedDamages}
            id={Field.SelfInflictedDamages}
            isChecked={fields.selfInflictedDamages}
            py={2}
            alignItems="baseline"
            onChange={(e) => {
              handleChange(Field.SelfInflictedDamages, e.target.checked);
              handleChange(Field.SelfInflictedDamagesAmount, null);
            }}
          >
            <div className="label">
              <Icon as={Lamp} flexShrink={0} mr={2} w={6} h={6} />
              {t('selfInflictedDamagesLabel')}
            </div>
          </Checkbox>
        </>
      </Question>
      <RenderIf condition={fields.privateLiability}>
        <PrivateLiabilityExtraQuestions />
      </RenderIf>
      <RenderIf
        condition={
          (fields.nonOwnerDriverInsurance !== null && fields.theftNotAtHome) ||
          (fields.theftNotAtHome && !fields.privateLiability)
        }
      >
        <SingleChoiceSelection
          errorMessage={errors.theftNotAtHomeAmount ? t('theftNotAtHomeAmountError') : undefined}
          name={Field.TheftNotAtHomeAmount}
          title={t('theftNotAtHomeAmountQuestionTitle')}
          intro={t('theftNotAtHomeAmountQuestion')}
          options={theftNotAtHomeOptions}
          currentValue={fields.theftNotAtHomeAmount}
          onChange={(newValue) => {
            handleChange(
              Field.TheftNotAtHomeAmount,
              newValue,
              fields.selfInflictedDamages ? Field.SelfInflictedDamagesAmount : Field.DateOfBirth,
            );
          }}
        />
      </RenderIf>
      <RenderIf condition={renderSelfInflictedDamagesAmount()}>
        <SingleChoiceSelection
          errorMessage={errors.selfInflictedDamagesAmount ? t('selfInflictedDamagesAmountError') : undefined}
          name={Field.SelfInflictedDamagesAmount}
          title={t('selfInflictedDamagesAmountQuestionTitle')}
          intro={t('selfInflictedDamagesAmountQuestion')}
          options={selfInflictedDamagesOptions}
          currentValue={fields.selfInflictedDamagesAmount}
          onChange={(newValue) => handleChange(Field.SelfInflictedDamagesAmount, newValue, Field.DateOfBirth)}
        />
      </RenderIf>
    </>
  );
};
