import { logger } from '../common/log/logger';
import { QuoteDto, QuoteStatus } from './../types/Responses';
import { RequestQuoteCommand } from './../types/Requests';
import { InsuranceCompany } from '../enums';
import { insuranceApiClient } from '../lib/apiClients/insuranceApiClient';

/**
 * Retrieve a quote for the given inquiry form data.
 * @param inquiryForm
 */
export const requestInsuranceQuote = async (inquiryForm: RequestQuoteCommand) => {
  try {
    const response = await insuranceApiClient.post('/quote/create-request', inquiryForm);
    return response.data;
  } catch (error) {
    logger.error('Unable to fetch quote info for the given inquiry -', error.message);
    throw error;
  }
};

export const getInsuranceQuoteStatus = async (requestId: string) => {
  const response = await insuranceApiClient.get(`/quote/${requestId}/status`);
  return response.data as QuoteStatus;
};

export const getInsuranceQuotes = async (requestId: string) => {
  const response = await insuranceApiClient.get(`/quote/${requestId}`);
  return response.data as QuoteDto[];
};

export const getInsuranceProductQuote = async (
  requestId: string,
  insuranceCompany: InsuranceCompany,
  productId: number,
) => {
  const response = await insuranceApiClient.get(`/quote/${requestId}/${insuranceCompany}/${productId}`);
  return response.data as QuoteDto;
};

export const resultsTimeoutSendEmail = async (
  quoteRequestId: string,
  email: string,
  permaLink: string,
): Promise<boolean> => {
  try {
    await insuranceApiClient.post(`/quote/send-premiums-notification`, {
      quoteRequestId,
      email,
      permaLink,
    });
    return true;
  } catch (error) {
    logger.error('Unable to send the timeout email for the given inquiry', error.message);
    throw error;
  }
};
