import * as React from 'react';
import { SelectQuestion, DropdownSelectOption } from '@scout24ch/fs24-design-system';

export interface SelectInputProps {
  errorMessage?: string;
  title?: string;
  intro?: string;
  name: string;
  value?: any;
  onChange(newValue: any): void;
  options: DropdownSelectOption<any>[];
}

export const SelectInput: React.FC<SelectInputProps> = (props) => (
  <>
    <SelectQuestion
      errorMessage={props.errorMessage}
      label={props.title || ''}
      options={props.options}
      description={props.intro}
      defaultValue={props.options[0]}
      name={props.name}
      value={props.value || ''}
      onChange={props.onChange}
    />
    <div className="scroll-checkpoint" data-scroll={props.name} />
  </>
);
